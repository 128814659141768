import React, { useState } from "react";
import Context from "./index";
import { create, getAll, remove } from "../../Api/WebApi";
import { message, Form } from "antd";

const WebMyAccountProvider = ({ children, ...props }) => {
  const [webLoginPopup, setWebLoginPopup] = useState(false);
  const [webLoginStepOneLoader, setWebLoginStepOneLoader] = useState(false);
  const [webLoginStepOneLoaderV2, setWebLoginStepOneLoaderV2] = useState(false);
  const [webLoginStepTwoLoader, setWebLoginStepTwoLoader] = useState(false);
  const [webLoginStepOneData, setWebLoginStepOneData] = useState({});
  const [webLoginSteps, setWebLoginSteps] = useState(1);
  const [addAddressForm] = Form.useForm();
  const [editAddressForm] = Form.useForm();
  const [addAddressLoader, setAddAddressLoader] = useState(false);
  const [editAddressLoader, setEditAddressLoader] = useState(false);
  const [editAddressPopup, setEditAddressPopup] = useState(false);
  const [addAddressPopup, setAddAddressPopup] = useState(false);
  const [stateLoader, setStateLoader] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [cityLoader, setCityLoader] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [addressSingle, setAddressSingle] = useState({});
  const [addressListLoader, setAddressListLoader] = useState(false);
  const [addressSingleLoader, setAddressSingleLoader] = useState(false);
  const [addressDeleteLoader, setAddressDeleteLoader] = useState(false);
  const [myOrdersList, setMyOrdersList] = useState([]);
  const [myOrdersListLoader, setMyOrdersListLoader] = useState(false);
  const [myOrdersSingle, setMyOrdersSingle] = useState({});
  const [myOrdersSingleLoader, setMyOrdersSingleLoader] = useState(false);
  const [cartDrawer, setCartDrawer] = useState(false);
  const [myOdersFilter, setMyOdersFilter] = useState({
    limit: 10,
    page: 1,
  });
  const [addReviewLoader, setAddReviewLoader] = useState(false);
  const [addReviewForm] = Form.useForm();
  const [getMyProfileLoader, setGetMyProfileLoader] = useState(false);
  const [getMyProfileData, setGetMyProfileData] = useState({});
  const [myProfileForm] = Form.useForm();
  const [updateMyProfileLoader, setUpdateMyProfileLoader] = useState(false);
  const [editProfilePopup, setEditProfilePopup] = useState(false);
  const [loginDirect, setLoginDirect] = useState("");
  async function updateMyProfile(data) {
    setUpdateMyProfileLoader(true);
    await create("myaccount.php?action=editprofile", data)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          message.success(res?.message);
          setUpdateMyProfileLoader(false);
          setEditProfilePopup(false);
          getMyProfile();
        } else {
          message.error(res?.message);
          setUpdateMyProfileLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setUpdateMyProfileLoader(false);
      });
  }
  async function getMyProfile() {
    setGetMyProfileLoader(true);
    await getAll("myaccount.php?action=getprofile")
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setGetMyProfileData(res?.data);
          myProfileForm.setFieldsValue(res?.data);
          setGetMyProfileLoader(false);
        } else {
          message.error(res?.message);
          setGetMyProfileLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setGetMyProfileLoader(false);
      });
  }
  async function addReview(data, id) {
    setAddReviewLoader(true);
    await create("web.php?action=addreview", {
      product_id: id,
      ...data,
    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          addReviewForm.resetFields();
          setAddReviewLoader(false);
          message.success(res?.message);
        } else {
          message.error(res?.message);
          setAddReviewLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAddReviewLoader(false);
      });
  }
  async function fetchWebLoginStepOne(phone) {
    if (!phone) {
      message.error("Please enter phone number");
      return;
    }
    setWebLoginStepOneLoader(true);
    await create("customer_login.php?action=customerlogin", {
      phone: phone,
    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setWebLoginStepOneData(res?.data);
          setWebLoginSteps(2);
          setWebLoginStepOneLoader(false);
        } else {
          message.error(res?.message);
          setWebLoginStepOneLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setWebLoginStepOneLoader(false);
      });
  }
  async function fetchWebLoginStepTwo(phone, otp_code) {
    if (!phone || !otp_code) {
      message.error("Please enter otp code");
      return;
    }
    setWebLoginStepTwoLoader(true);
    await create("customer_login.php?action=otpverify", {
      phone: phone,
      otp_code: otp_code.join(""),
    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          localStorage.setItem("nattu_customer_login", true);
          localStorage.setItem("nattu_customer_token", res?.data?.token);
          if (loginDirect === "checkout") {
            window.location.href = "/checkout";
          } else {
            window.location.href = "/";
          }
          setWebLoginStepTwoLoader(false);
        } else {
          message.error(res?.message);
          setWebLoginStepTwoLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setWebLoginStepTwoLoader(false);
      });
  }

  async function fetchWebLoginStepOneV2(phone) {
    if (!phone) {
      message.error("Please enter phone number");
      return;
    }
    setWebLoginStepOneLoaderV2(true);
    await create("customer_login.php?action=customerlogin_v2", {
      phone: phone,
    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          localStorage.setItem("nattu_customer_login", true);
          localStorage.setItem("nattu_customer_token", res?.data?.token);
          if (loginDirect === "checkout") {
            window.location.href = "/checkout";
          } else {
            window.location.href = "/";
          }
          setWebLoginStepOneLoaderV2(false);
        } else {
          message.error(res?.message);
          setWebLoginStepOneLoaderV2(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setWebLoginStepOneLoader(false);
      });
  }

  async function addAddress(data) {
    setAddAddressLoader(true);
    await create("myaccount.php?action=createaddress", data)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          addAddressForm.resetFields();
          message.success(res?.message);
          fetchAddressList();
          setAddAddressPopup(false);
          setAddAddressLoader(false);
        } else {
          message.error(res?.message);
          setAddAddressLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAddAddressLoader(false);
      });
  }
  async function editAddress(data) {
    setEditAddressLoader(true);
    await create(
      "myaccount.php?action=updateaddress&address_id=" +
        addressSingle?.address_id,
      data
    )
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          editAddressForm.resetFields();
          message.success(res?.message);
          fetchAddressList();
          setEditAddressPopup(false);
          setEditAddressLoader(false);
        } else {
          message.error(res?.message);
          setEditAddressLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAddAddressLoader(false);
      });
  }

  async function fetchAddressList() {
    setAddressListLoader(true);
    await getAll("myaccount.php?action=addresslist&page=1&limit=10")
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAddressList(res?.data);
          setAddressListLoader(false);
        } else {
          setAddressListLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAddressListLoader(false);
      });
  }

  async function stateLoad() {
    addAddressForm.setFieldValue("state", "");
    addAddressForm.setFieldValue("city", "");
    setStateList([]);
    setCityList([]);
    setStateLoader(true);
    await fetch("https://countriesnow.space/api/v0.1/countries/states", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        country: "India",
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const ress = res?.data?.states?.map((state) => {
          return { label: state?.name, value: state?.name };
        });
        setStateList(ress);
        setStateLoader(false);
      })
      .catch((err) => {
        setStateLoader(false);
      });
  }

  async function cityLoad(e) {
    addAddressForm.setFieldValue("city", "");
    setCityList([]);
    setCityLoader(true);
    await fetch("https://countriesnow.space/api/v0.1/countries/state/cities", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        country: "India",
        state: e,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        const ress = res?.data?.map((city) => {
          return { label: city, value: city };
        });
        setCityList(ress);
        setCityLoader(false);
      })
      .catch((err) => {
        setCityLoader(false);
      });
  }

  async function fetchSingleAddress(id) {
    setAddressSingleLoader(true);
    await getAll(`myaccount.php?action=singleaddress&address_id=${id}`).then(
      (res) => {
        if (res?.status === 200 && res?.success === true) {
          console.log("res?.data", res?.data);
          setAddressSingle(res?.data);
          editAddressForm.setFieldsValue(res?.data);
          stateLoad();
          cityLoad(res?.data?.state);
          setAddressSingleLoader(false);
        } else {
          setAddressSingleLoader(false);
        }
      }
    );
  }

  async function deleteAddress(id) {
    setAddressDeleteLoader(true);
    await remove(`myaccount.php?action=deleteaddress&id=${id}`).then((res) => {
      if (res?.status === 200 && res?.success === true) {
        message.success(res?.message);
        fetchAddressList();
        setAddressDeleteLoader(false);
      } else {
        message.error(res?.message);
        setAddressDeleteLoader(false);
      }
    });
  }

  async function setDefaultAddress(id, callback = () => {}) {
    await getAll(`myaccount.php?action=defaultaddress&id=${id}`).then((res) => {
      if (res?.status === 200 && res?.success === true) {
        callback();
        message.success(res?.message);
        fetchAddressList();
      } else {
        message.error(res?.message);
      }
    });
  }

  async function fetchOrderList() {
    setMyOrdersListLoader(true);
    setMyOrdersList([]);
    await getAll(
      `myaccount.php?action=myorders&page=${myOdersFilter?.page}&limit=${myOdersFilter?.limit}`
    )
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setMyOrdersList(res?.data);
          setMyOrdersListLoader(false);
        } else {
          setMyOrdersList([]);
          setMyOrdersListLoader(false);
        }
      })
      .catch((err) => {
        setMyOrdersList([]);
        message.error("Something went wrong");
        setMyOrdersListLoader(false);
      });
  }

  async function fetchSingleOrder(id) {
    setMyOrdersSingleLoader(true);
    setMyOrdersSingle({});
    await getAll(`myaccount.php?action=myordersdetails&order_id=${id}`).then(
      (res) => {
        if (res?.status === 200 && res?.success === true) {
          setMyOrdersSingle(res?.data);
          setMyOrdersSingleLoader(false);
        } else {
          setMyOrdersSingleLoader(false);
        }
      }
    );
  }

  function formatDate(date1) {
    const date = new Date(date1);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate;
  }

  return (
    <Context.Provider
      value={{
        ...props,
        webLoginPopup,
        setWebLoginPopup,
        webLoginStepOneLoader,
        setWebLoginStepOneLoader,
        webLoginStepTwoLoader,
        setWebLoginStepTwoLoader,
        webLoginStepOneData,
        setWebLoginStepOneData,
        webLoginSteps,
        setWebLoginSteps,
        fetchWebLoginStepOne,
        fetchWebLoginStepTwo,
        addAddress,
        addAddressLoader,
        setAddAddressLoader,
        addAddressForm,
        addAddressPopup,
        setAddAddressPopup,
        stateLoad,
        cityLoad,
        stateLoader,
        setStateLoader,
        stateList,
        setStateList,
        cityLoader,
        setCityLoader,
        cityList,
        setCityList,
        fetchAddressList,
        addressList,
        setAddressList,
        addressListLoader,
        setAddressListLoader,
        editAddress,
        editAddressForm,
        editAddressLoader,
        setEditAddressLoader,
        editAddressPopup,
        setEditAddressPopup,
        fetchSingleAddress,
        addressSingleLoader,
        setAddressSingleLoader,
        addressSingle,
        setAddressSingle,
        deleteAddress,
        addressDeleteLoader,
        setAddressDeleteLoader,
        setDefaultAddress,
        myOrdersList,
        setMyOrdersList,
        myOrdersListLoader,
        setMyOrdersListLoader,
        myOrdersSingle,
        setMyOrdersSingle,
        myOrdersSingleLoader,
        setMyOrdersSingleLoader,
        myOdersFilter,
        setMyOdersFilter,
        fetchOrderList,
        fetchSingleOrder,
        formatDate,
        cartDrawer,
        setCartDrawer,
        addReviewLoader,
        setAddReviewLoader,
        addReviewForm,
        addReview,
        getMyProfileLoader,
        setGetMyProfileLoader,
        getMyProfileData,
        setGetMyProfileData,
        myProfileForm,
        updateMyProfileLoader,
        setUpdateMyProfileLoader,
        updateMyProfile,
        getMyProfile,
        editProfilePopup,
        setEditProfilePopup,
        loginDirect,
        setLoginDirect,
        webLoginStepOneLoaderV2,
        setWebLoginStepOneLoaderV2,
        fetchWebLoginStepOneV2,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default WebMyAccountProvider;
