import React, { useState } from "react";
import Context from "./index";
import { create, getAll, remove } from "../../Api/AuthApi";
import { message, Form } from "antd";
const AdminProFeatureProvider = ({ children, ...props }) => {
  const [adProFeatureAddLoader, setAdProFeatureAddLoader] = useState(false);
  const [adProFeatureForm] = Form.useForm();
  const [adProFeatureEditLoader, setAdProFeatureEditLoader] = useState(false);
  const [adProFeatureFormEdit] = Form.useForm();
  const [adProFeatureType, setAdProFeatureType] = useState("pro_banner");
  const [adProFeaturePopup, setAdProFeaturePopup] = useState(false);
  const [adProFeatureEditPopup, setAdProFeatureEditPopup] = useState(false);
  const [adProFeatureList, setAdProFeatureList] = useState([]);
  const [adProFeatureListLoader, setAdProFeatureListLoader] = useState(false);
  const [adProFeatureDeleteLoader, setAdProFeatureDeleteLoader] =
    useState(false);
  const [adProFeatureSingle, setAdProFeatureSingle] = useState({});
  const [adProFeatureSingleLoader, setAdProFeatureSingleLoader] =
    useState(false);
  async function adProFeatureAdd(data = {}, image = null, calback, product_id) {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdProFeatureAddLoader(true);
    const form_data = new FormData();
    if (data?.description) {
      form_data.append("description", data?.description || "");
    }
    if (data?.product_id) {
      form_data.append("product_id", data?.product_id || "");
    }
    if (data?.title) {
      form_data.append("title", data?.title || "");
    }
    if (data?.video_url) {
      form_data.append("video_url", data?.video_url || "");
    }
    if (data?.orders) {
      form_data.append("orders", data?.orders || "");
    }
    form_data.append("type", adProFeatureType || "");
    form_data.append("status", data?.status === true ? 1 : 0 || 1);
    form_data.append("product_id", product_id || "");
    if (image) {
      form_data.append("image", image);
    }
    await create("product_feature.php?action=pro_feature_create", form_data)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdProFeaturePopup(false);
          adProFeatureForm.resetFields();
          adProFeatureListFetch(product_id);
          calback();
          setAdProFeatureAddLoader(false);
        } else {
          message.error(res?.message);
          setAdProFeatureAddLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdProFeatureAddLoader(false);
      });
  }

  async function adProFeatureEdit(
    data = {},
    image = null,
    calback,
    product_id
  ) {
    const token_data = localStorage.getItem("nattu_admin_token");
    if (!token_data) return;
    setAdProFeatureEditLoader(true);
    const form_data = new FormData();
    if (data?.description) {
      form_data.append("description", data?.description || "");
    }
    if (data?.product_id) {
      form_data.append("product_id", data?.product_id || "");
    }
    if (data?.title) {
      form_data.append("title", data?.title || "");
    }
    if (data?.video_url) {
      form_data.append("video_url", data?.video_url || "");
    }
    if (data?.orders) {
      form_data.append("orders", data?.orders || "");
    }
    form_data.append("type", adProFeatureType || "");
    form_data.append("status", data?.status == true ? 1 : 0);
    if (image) {
      form_data.append("image", image);
    }
    await create(
      "product_feature.php?action=pro_feature_update&pro_feature_id=" +
        adProFeatureSingle?.pro_feature_id || "",
      form_data
    )
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdProFeatureEditPopup(false);
          setAdProFeatureSingle({});
          adProFeatureFormEdit.resetFields();
          adProFeatureListFetch(product_id);
          calback();
          setAdProFeatureEditLoader(false);
        } else {
          message.error(res?.message);
          setAdProFeatureEditLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdProFeatureEditLoader(false);
      });
  }

  async function adProFeatureSingleFetch(id) {
    setAdProFeatureSingleLoader(true);
    await getAll(`product_feature.php?action=pro_feature_single_list&id=${id}`)
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdProFeatureSingle(res?.data);
          adProFeatureFormEdit.setFieldsValue({
            ...res?.data,
            status: res?.data?.status == 1 ? true : false,
            description: res?.data?.description || "",
          });
          setAdProFeatureSingleLoader(false);
        } else {
          setAdProFeatureSingle({});
          setAdProFeatureSingleLoader(false);
        }
      })
      .catch((err) => {
        setAdProFeatureSingle({});
        setAdProFeatureSingleLoader(false);
      });
  }

  async function adProFeatureListFetch(p_id) {
    setAdProFeatureListLoader(true);
    await getAll("product_feature.php?action=pro_feature_list", {
      type: adProFeatureType || "",
      id: p_id || "",
    })
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          setAdProFeatureList(res?.data);
          setAdProFeatureListLoader(false);
        } else {
          setAdProFeatureList([]);
          setAdProFeatureListLoader(false);
        }
      })
      .catch((err) => {
        setAdProFeatureList([]);
        setAdProFeatureListLoader(false);
      });
  }

  async function adProFeatureRemove(id, p_id) {
    setAdProFeatureDeleteLoader(true);
    await getAll(
      `product_feature.php?action=pro_feature_delete&pro_feature_id=${id}`
    )
      .then((res) => {
        if (res?.status === 200 && res?.success === true) {
          adProFeatureListFetch(p_id);
          setAdProFeatureDeleteLoader(false);
        } else {
          message.error(res?.message);
          setAdProFeatureDeleteLoader(false);
        }
      })
      .catch((err) => {
        message.error("Something went wrong");
        setAdProFeatureDeleteLoader(false);
      });
  }

  return (
    <Context.Provider
      value={{
        ...props,
        adProFeatureAddLoader,
        setAdProFeatureAddLoader,
        adProFeatureForm,
        adProFeatureType,
        setAdProFeatureType,
        adProFeaturePopup,
        setAdProFeaturePopup,
        adProFeatureAdd,
        adProFeatureListFetch,
        adProFeatureList,
        setAdProFeatureList,
        adProFeatureListLoader,
        setAdProFeatureListLoader,
        adProFeatureRemove,
        adProFeatureDeleteLoader,
        setAdProFeatureDeleteLoader,
        adProFeatureEditPopup,
        setAdProFeatureEditPopup,
        adProFeatureSingleFetch,
        adProFeatureSingleLoader,
        setAdProFeatureSingleLoader,
        adProFeatureFormEdit,
        adProFeatureSingle,
        setAdProFeatureSingle,
        adProFeatureEdit,
        adProFeatureEditLoader,
        setAdProFeatureEditLoader,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AdminProFeatureProvider;
